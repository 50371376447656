
export const onLoad = () => {
	document.onreadystatechange = function () {
		if (document.readyState === "interactive") {
			document.body.classList.remove("loading");

			document.body.classList.add("loaded");
		} 
	};
};
