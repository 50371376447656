let instance = null;
// import Nav from "../components/Nav";
// import Observer from "../components/Observer";
import Overlays from "../components/Overlays";
import Cursor from "../components/Cursor";

export default class App { 
	constructor() {

		// make singleton
		if (instance) {
			return instance;
		}
		instance = this;

		// global access

		window.app = this;

		// get context

		this.getContext();

		window.addEventListener("resize", () => {
			this.getContext();
		});

		/**
		 * Components
		 */

		// this.observer = new Observer();
		// this.nav = new Nav();
		this.overlays = new Overlays();
		this.cursor = new Cursor();


	}
	getContext() {
		// Tells us if App is Mobile, and height of Viewport.

		if (window.matchMedia("(max-width: 1024px)").matches) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}

		if (window.location.host.includes("localhost")) {
			this.URL =
				"http://" +
				window.location.host +
				`/${REPO}/wp-content/themes/${THEME}`;
		} else {
			this.URL =
				"https://" + window.location.host + `/wp-content/themes/${THEME}`;
		}

	}
}