export default class Cursor {
	constructor() {

		this.windowW = window.innerWidth

		document.onmousemove = this.handleMouseMove;

	}
	handleMouseMove(event){

		let xDistance = window.innerWidth - event.clientX;
		let yDistance = window.innerHeight - event.clientY;

		if(xDistance < '300'){
			document.body.classList.add('overlay--right');
		} else {
			document.body.classList.remove('overlay--right');
		}

		if(yDistance < '300'){
			document.body.classList.add('overlay--bottom');
		} else {
			document.body.classList.remove('overlay--bottom');
		}

		document.body.style.setProperty('--mouseX', event.clientX+'px');
		document.body.style.setProperty('--mouseY', event.clientY+'px');

	}
}