export default class Overlays {
	constructor() {

		this.highlights = document.querySelectorAll(".hover");
		this.body = document.body

		this.highlights && this.highlights.forEach( (highlight) => {
			const parent = highlight.parentNode;
			highlight.addEventListener("mouseover", () => {
				parent.classList.add("highlighted");
				this.body.classList.add("highlights-active");
			})
			highlight.addEventListener("mouseout", () => {
				parent.classList.remove("highlighted");
				this.body.classList.remove("highlights-active");
			})
		});

	}
}